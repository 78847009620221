<template>
  <!-- 工地巡查大屏页面----加轮播 -->
  <div class="v-container">
    <div class="sub-container">
      <div class="v-title">
        <div class="v-title-left">{{ nowTime }}</div>
        工地巡查大屏
        <div class="v-title-right">
          <span class="SwiperBtn">
            <span v-show="showOpenBtn" @click="openSwiper()">开启轮播</span>
            <span v-show="!showOpenBtn" @click="closeSwiper()">关闭轮播</span>
          </span>

          <!-- <span @click="jumpBack"><a-icon type="logout" /> 退出</span> -->
          <a-select
            default-value="15s"
            style="width: 120px"
            @change="handleChange"
            :disabled="!showOpenBtn"
          >
            <a-select-option
              v-for="item in selectTimeList"
              :key="item.value"
              :value="item.value"
            >
              {{ item.label }}
            </a-select-option>
          </a-select>
        </div>
      </div>
      <vSearch
        :current="current"
        @changeList="changeList"
        ref="vSearch"
      ></vSearch>
      <a-row>
        <a-col :span="8" v-for="item in list" :key="item.ws_id">
          <div class="v-box" @click="jumpDetail(item.ws_id)">
            <img :src="item.file_path" />
            <!-- <img :src="item.file_path" v-else="item.file_path" /> -->
            <div class="v-box-info">
              <div class="v-box-title">{{ item.ws_name }}</div>
              <div>
                {{ "工程管家：" + (item.w_gj_name ? item.w_gj_name : "") }}
                <!-- 手机号
                  +
                  "(" +
                  (item.w_gj_phone ? item.w_gj_phone : "-") +
                  ")" -->
              </div>
              <div>
                {{ "项目经理：" + (item.w_jl_name ? item.w_jl_name : "") }}
                <!--手机号
                   +
                  "(" +
                  (item.w_jl_phone ? item.w_jl_phone : "-") +
                  ")" -->
              </div>
              <div style="position: absolute; bottom: 5px; right: 10px">
                {{ "剩余" + $calcDate(item.d_remain_day) }}
              </div>
            </div>
          </div>
        </a-col>
      </a-row>
      <a-pagination
        class="v-page"
        v-model="current"
        :default-current="1"
        :total="total"
        :page-size="6"
        :show-total="(total) => `共 ${total} 条`"
        @change="onChange"
        show-less-items
        show-quick-jumper
      />
      <!--  @showSizeChange="sizeChange" -->
    </div>
  </div>
</template>
<script>
import vSearch from "./vSearch.vue";
export default {
  data() {
    return {
      list: [],
      // pageSize: 6, //当前页显示多少条
      current: 1,
      total: 0,
      timer: "", //定义一个定时器
      nowTime: "", // 当前日期时间
      changeListTimer: "",
      showOpenBtn: true,
      selectTimeList: [],
      selectTime: 15,
    };
  },
  components: { vSearch },
  created() {
    window.onbeforeunload = function () {
      window.alert("关闭页面？");
      localStorage.setItem("inspection_page", 1);
      return;
    };
    if (localStorage.getItem("inspection_page")) {
      this.current = localStorage.getItem("inspection_page");
    }
    // 下拉框数据
    for (let index = 1; index < 61; index++) {
      this.selectTimeList.push({ label: index + "s", value: index });
    }
  },
  mounted() {
    this.getTime();
  },
  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  },
  methods: {
    getTime() {
      this.timer = setInterval(() => {
        let timeDate = new Date();
        let year = timeDate.getFullYear();
        let mounth = timeDate.getMonth() + 1;
        mounth = mounth >= 10 ? mounth : "0" + mounth;
        let day = timeDate.getDate();
        day = day >= 10 ? day : "0" + day;
        let hours = timeDate.getHours();
        hours = hours >= 10 ? hours : "0" + hours;
        let minutes = timeDate.getMinutes();
        minutes = minutes >= 10 ? minutes : "0" + minutes;
        let seconds = timeDate.getSeconds();
        seconds = seconds >= 10 ? seconds : "0" + seconds;
        let week = timeDate.getDay();
        let weekArr = [
          "星期日",
          "星期一",
          "星期二",
          "星期三",
          "星期四",
          "星期五",
          "星期六",
        ];
        this.nowTime = `${year}-${mounth}-${day} ${hours}:${minutes}:${seconds} ${weekArr[week]}`;
      }, 1000);
    },
    jumpBack() {
      this.$router.push({ path: "SiteList" });
    },
    // 分页
    onChange(current, size) {
      this.$refs.vSearch.changePageSearch(current);
      // this.getShowSource();
    },
    changeList(result, page, sum) {
      this.list = result;
      this.current = page;
      this.total = sum;
      // console.log(result, page, sum);
    },
    // //  分页显示条数的变化
    // sizeChange(current, size) {
    //   this.current = current;
    //   this.pageSize = size;
    //   this.getShowSource();
    // },
    // // 实现分页效果
    // getShowSource() {
    //   const data = this.data;
    //   //后端返回的全部数据
    //   let keyValue = 0;
    //   this.total = data.length;
    //   for (let i = 0; i < this.total; i++) {
    //     keyValue = keyValue + 1;
    //     let key = { key: keyValue };
    //     data[i] = Object.assign(data[i], key);
    //   }
    //   this.loading = false;
    //   let start = this.pageSize * this.current - this.pageSize;
    //   let end = this.pageSize * this.current;
    //   let dataSource = data.slice(start, end);
    //   this.dataSource = dataSource;
    //   this.$set(this.dataSource, [], dataSource);
    // },
    jumpDetail(ws_id) {
      localStorage.setItem("inspection_page", this.current);
      this.$router.push({
        path: "/PatrolDetail",
        query: {
          ws_id, //工地id
          list: this.list, //此页的数据
          total: this.total, //共几页
          current: this.current, //第几页
        },
      });
    },
    // 开启轮播
    openSwiper() {
      this.current = 0;
      this.onChange(this.current);
      this.showOpenBtn = false;
      let total1 = Math.ceil(this.total / 6) + "";
      // console.log(this.selectTime);
      this.changeListTimer = setInterval(() => {
        this.current += 1;

        console.log("this.current", this.current - 1, total1);
        // // 跳转到最后一页的时候，关闭轮播
        if (this.current - 2 == total1 - 1) {
          // if (this.changeListTimer) {
          // clearInterval(this.changeListTimer);
          // this.showOpenBtn = true;
          // }
          // this.current = this.current - 1;
          // console.log("this.current1111111", this.current - 2, total1 - 1);
          this.current = 0;
          this.onChange(this.current);
          // console.log("this.current1111111", this.current - 2, total1 - 1);
        } else {
          this.onChange(this.current);
        }
        // console.log("this.current", this.current, this.current - 2, total1 - 1);
        // }, 1000 * 15); //15秒切换一次
      }, 1000 * this.selectTime); //15秒切换一次

      // }, 1000);
    },
    // 关闭轮播
    closeSwiper() {
      this.showOpenBtn = true;
      clearInterval(this.changeListTimer);
    },
    // 下拉框
    handleChange(value) {
      console.log(`selected ${value}`);
      this.selectTime = value;
    },
  },
};
</script>
<style lang="less" scoped>
.v-container {
  background: #0a1621;
  min-width: 1200px;
  height: 100%;
  overflow: auto;
}
.sub-container {
  min-height: 100%;
  margin: 0 10px;
}
.v-title {
  position: relative;
  color: #66ffff;
  height: 70px;
  line-height: 75px;
  font-size: 27px;
  background: url("../../assets/images/visualize/title_inspection.png")
    no-repeat;
  background-position: center center;
  background-size: auto 100%;
  .v-title-left {
    position: absolute;
    left: 30px;
    color: #fff;
    font-size: 14px;
    line-height: 60px;
  }
  .v-title-right {
    position: absolute;
    right: 30px;
    top: 0;
    color: #fff;
    font-size: 14px;
    line-height: 60px;
    // cursor: pointer;
    // &:hover {
    //   color: #66ffff;
    // }
  }
}
.ant-row {
  padding: 14px;
}
.ant-col {
  padding: 8px;
  color: #fff;
}
.v-box {
  margin-top: 10px;

  position: relative;
  border-radius: 5px;
  min-height: 150px;
  border: 1px solid rgba(0, 0, 0, 0);
  cursor: pointer;
  &:hover {
    border: 1px solid #66ffff;
  }
  img {
    background-color: #10324d;
    width: 100%;
    height: auto;
    // height: 90%;
    border-radius: 5px;
    min-height: 300px;
  }
}
.v-box-info {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 0 10px 5px 10px;
  text-align: left;
  background-color: rgba(71, 180, 234, 0.4);
}
.v-box-title {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.transfer-modal /deep/ .ant-modal {
  width: 1000px !important;
}
.site-trans /deep/ .ant-transfer-list {
  width: 450px;
  height: 300px;
}
.v-page {
  margin-bottom: 0px;
  /deep/ .ant-pagination-total-text {
    color: #ccc;
  }
  /deep/ .ant-pagination-item-link {
    background-color: rgba(0, 0, 0, 0);
    color: #fff;
  }
  /deep/ .ant-pagination-item {
    background-color: rgba(0, 0, 0, 0);
    &:hover {
      border-color: #47b4ea;
    }
    a {
      color: #fff;
      &:hover {
        color: #47b4ea;
      }
    }
  }
  /deep/ .ant-pagination-item-active {
    background-color: #47b4ea;
    &:hover {
      border-color: #47b4ea;
    }
    a {
      &:hover {
        color: #fff;
      }
    }
  }
  /deep/ .ant-pagination-item-ellipsis {
    color: #fff;
  }
  /deep/ .ant-pagination-options-quick-jumper {
    color: #ccc;
    input {
      color: #47b4ea;
      background-color: rgba(0, 0, 0, 0);
    }
  }
}
.ant-row {
  padding-top: 0;
  padding-bottom: 0;
}
.SwiperBtn {
  margin-right: 10px;
  cursor: pointer;
  &:hover {
    color: #66ffff;
  }
}
</style>
