<template>
  <div style="margin-top: 5px">
    <a-form :model="form" layout="inline">
      <a-row class="antd-row1" v-show="expand">
        <a-col :span="3" v-for="item in formOption" :key="item.value">
          <!--   colon="false"  去掉label里的冒号 -->
          <a-form-item
            :colon="false"
            :labelCol="{ span: 12, offset: 3 }"
            :label="item.label"
          >
            <!-- 输入 -->
            <a-input
              v-if="item.type === 'input'"
              v-model="form[item.value]"
              :placeholder="'请输入' + item.label"
            ></a-input>
            <!-- 搜索       :placeholder="'请选择' + item.label"-->
            <a-select v-if="item.type === 'select'" v-model="form[item.value]">
              <a-select-option
                v-for="opt in item.options"
                :key="opt.value"
                :value="opt.value"
                >{{ opt.label }}</a-select-option
              >
            </a-select>
            <a-tree-select
              v-if="item.type === 'TreeSelect'"
              v-model="TreeSelectValue"
              allow-clear
              tree-default-expand-all
              :dropdown-style="{ maxHeight: '300px', overflow: 'auto' }"
              @change="TreeSelectChange"
              labelInValue
            >
              <a-tree-select-node
                v-for="opt in item.options"
                :key="opt.value"
                :value="opt.value"
                :title="opt.label"
              >
                <!--:value="opt.value"  v-model="queryParam[item.value][0]" -->
                <!-- :selectable="false" 是否可选 -->
                <a-tree-select-node
                  v-for="item in opt.cs_data"
                  :key="item.cs_id"
                  :value="item.cs_id"
                  :title="item.cs_name"
                >
                  <!--:value="item.cs_id"  v-model="queryParam[item.value][1]" -->
                </a-tree-select-node>
              </a-tree-select-node>
            </a-tree-select>

            <!-- 弹出框查询 -->
            <div v-if="item.type === 'modalSelect'" class="modal-select">
              <a @click="handleClick(item)" style="color: #47b4ea">选择</a>
              <span>{{ "&ensp;已选" + item.selectLength + "个" }}</span>
            </div>
          </a-form-item>
        </a-col>
        <a-col :span="1" class="s-button">
          <div class="v-btn" @click="searchHandle()">查询</div>
        </a-col>
      </a-row>
    </a-form>

    <a-modal
      class="transfer-modal"
      :title="modalTitle"
      okText="确定"
      cancelText="取消"
      :width="modalType === 'wsIds' ? 1000 : 520"
      :visible="visible"
      :confirm-loading="confirmLoading"
      @ok="setOk"
      @cancel="setCancel"
    >
      <a-transfer
        :class="modalType === 'wsIds' ? 'site-trans' : ''"
        :data-source="mockData"
        show-search
        :titles="titles"
        :filter-option="filterOption"
        :target-keys="targetKeys"
        :selected-keys="selectedKeys"
        :render="(item) => item.title"
        @selectChange="handleSelectChange"
        @change="handleChange"
      />
    </a-modal>
  </div>
</template>
<script>
import { GetNameList } from "@/api/site.js";
import { GetCompanies, GetCompaniesStore } from "@/api/device";
import { GetWorkers } from "@/api/worker";
import { GetPatrolphoto } from "@/api/patrol";
import { SelectOptions } from "@/config/SelectOptions.js";
import { urlConfig } from "@/config/config";
const CompanystoreList = [];
const formOption = [
  // {
  //   index: 0,
  //   label: "工地状态",
  //   value: "ws_status",
  //   type: "select",
  //   options: SelectOptions.SiteStatus,
  // },
  // {
  //   index: 1,
  //   label: "业务类型",
  //   value: "ws_service_type",
  //   type: "select",
  //   options: SelectOptions.SiteServiceType,
  // },
  {
    index: 1,
    label: "整洁度类型",
    value: "e_events",
    type: "select",
    options: SelectOptions.SiteNeatLevel,
    // defaultValue: "1",
  },
  {
    index: 2,
    label: "设备所属分公司",
    value: "bc_name",
    type: "TreeSelect",
    options: CompanystoreList,
  },
  {
    index: 3,
    label: "设备状态",
    value: "device_status",
    type: "select",
    options: SelectOptions.SiteDeviceStatus,
  },
  {
    index: 4,
    label: "合同编号",
    value: "ws_only",
    type: "input",
  },
  {
    index: 5,
    label: "工地打卡",
    value: "ws_attendance",
    type: "select",
    options: SelectOptions.SiteAttendanceType,
  },
  {
    index: 6,
    label: "分公司",
    value: "bc_ids",
    type: "modalSelect",
    method: "GetCompanies",
    selectLength: 0,
  },
  {
    index: 7,
    label: "工地名称",
    value: "ws_ids",
    type: "modalSelect",
    method: "GetServiceList",
    selectLength: 0,
  },
  {
    index: 8,
    label: "工程管家",
    value: "w_gj_ids",
    type: "modalSelect",
    // method: "GetWorkers",
    method: "GetWorkersGJ",
    selectLength: 0,
  },
  {
    index: 9,
    label: "项目经理",
    value: "w_jl_ids",
    type: "modalSelect",
    method: "GetWorkers",
    selectLength: 0,
  },
];
const orderParam = [
  "ws_status",
  "ws_service_type",
  "ws_attendance",
  "device_status",
  "imei",
  "bc_ids",
  "ws_ids",
  "w_gj_ids",
  "w_jl_ids",
  "ws_only",
  "e_events",
  "bc_id",
  "cs_id",
  "pageNo",
  "pageSize",
];

export default {
  name: "vSearch",
  props: {
    current: {
      type: Number,
      default: "1",
    },
  },
  data() {
    return {
      formOption,
      form: {
        e_events: "",
        ws_attendance: "",
        device_status: "1",
        bc_id: "",
        cs_id: "",
      },
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      expand: true,
      page: 1,
      // 弹出框
      visible: false,
      confirmLoading: false,
      modalType: "",
      modalTitle: "",
      // transfer
      titles: ["", ""],
      mockKey: "",
      mockIndex: null,
      mockData: [],

      targetKeys: [],
      selectedKeys: [],
      TreeSelectValue: [{ value: "", label: "全部" }],
      valueList: [],
    };
  },
  created() {
    this.page = this.current;
    if (localStorage.getItem("patrolParam")) {
      this.form = JSON.parse(localStorage.getItem("patrolParam")).form;
      // console.log(this.form);
      this.page = JSON.parse(localStorage.getItem("patrolParam")).page;
      if (localStorage.getItem("TreeSelectValue")) {
        console.log(
          this.form,
          JSON.parse(localStorage.getItem("TreeSelectValue"))
        );
        this.TreeSelectValue = JSON.parse(
          localStorage.getItem("TreeSelectValue")
        );
      }
    }

    window.addEventListener("beforeunload", (e) => {
      this.beforeunloadHandler(e);
    });

    window.addEventListener("unload", (e) => {
      this.unloadHandler(e);
    });

    this.search();
    this.GetCompaniesStore();
  },
  methods: {
    // 查询点击事件
    searchHandle() {
      this.page = 1;
      this.search();
    },
    changePageSearch(curPage) {
      this.page = curPage;
      this.search();
    },
    // 查询方法
    search() {
      // console.log("form", this.form);
      localStorage.setItem(
        "patrolParam",
        JSON.stringify({ form: this.form, page: this.page })
      );
      const param = Object.assign({}, this.form, {
        pageNo: this.page,
        pageSize: 6,
      });
      const requestParameters = this.$order(param, orderParam);
      const params = {
        ...requestParameters,
        verify: this.$verify(requestParameters, orderParam),
      };
      GetPatrolphoto(params).then((res) => {
        let list = [];
        res.data.data.forEach((el) => {
          if (el.file_path.length > 0) {
            el.file_path =
              urlConfig.baseUrl +
              "/files/picture/snapshot/casepic?filePath=" +
              el.file_path;
          } else {
            el.file_path = require("../../assets/images/62456709df3e17415c6e2751d25f28a.png");
          }
        });

        list = res.data.data;
        // console.log(list);
        this.$emit("changeList", list, res.data.page, res.data.sum);
      });
    },
    handleClick(item) {
      this.visible = true;
      this.modalTitle = item.label;
      this.titles[0] = item.label + "列表";
      this.titles[1] = "已选" + item.label;
      this.mockKey = item.value;
      this.mockIndex = item.index;
      if (item.value === "ws_ids") {
        this.modalType = "wsIds";
      } else {
        this.modalType = "";
      }
      // 查询列表
      console.log(this.form);
      this.targetKeys = this.form[item.value]
        ? this.form[item.value].split(",")
        : [];
      this.selectedKeys = this.targetKeys;
      if (item.method === "GetCompanies") {
        GetCompanies().then((res) => {
          res.data.forEach((el) => {
            el.key = "" + el.bc_id;
            el.title = el.bc_name;
            el.chosen = false;
          });
          this.mockData = res.data;
        });
      }
      if (item.method === "GetServiceList") {
        let params = {
          pageNo: "",
          pageSize: 0,
        };
        GetNameList(params).then((res) => {
          res.data.data.forEach((el) => {
            el.key = "" + el.ws_id;
            el.title = el.ws_name;
            el.chosen = false;
          });
          this.mockData = res.data.data;
          // console.log(res.data.data);
        });
      }
      if (item.method === "GetWorkers") {
        // this.mockData = [];
        let params = {
          pageSize: 1000,
          w_post: "2",
        };
        GetWorkers(params).then((res) => {
          res.data.data.forEach((el) => {
            el.key = "" + el.w_id;
            el.title = el.w_name;
            el.chosen = false;
            // console.log(el);
          });
          this.mockData = res.data.data;
        });
      }
      if (item.method === "GetWorkersGJ") {
        let params = {
          pageSize: 1000,
          w_post: "1",
        };
        GetWorkers(params).then((res) => {
          let mockDataGJ = res.data.data;

          mockDataGJ.forEach((el) => {
            el.key = "" + el.w_id;
            el.title = el.w_name;
            el.chosen = false;
          });
          this.mockData = mockDataGJ;
        });
      }
    },
    handleSelectChange(sourceSelectedKeys, targetSelectedKeys) {
      this.selectedKeys = [...sourceSelectedKeys, ...targetSelectedKeys];
    },
    setOk() {
      const mockData = this.mockData;
      const list = [];
      mockData.forEach((el) => {
        if (this.targetKeys.indexOf(el.key) > -1) {
          list.push(el);
        }
      });
      let ids = [];
      list.forEach((el) => {
        ids.push(el.key);
      });
      console.log(list);
      this.form[this.mockKey] = ids.toString();
      this.formOption[this.mockIndex - 1].selectLength = ids.length;
      // console.log(ids);
      this.visible = false;
    },
    setCancel() {
      this.visible = false;
    },
    // transfer
    filterOption(inputValue, option) {
      return option.title.indexOf(inputValue) > -1;
    },
    handleChange(targetKeys, direction, moveKeys) {
      this.targetKeys = targetKeys;
      this.selectedKeys = targetKeys;
    },
    GetCompaniesStore() {
      let order = ["bc_id", "bc_name", "cs_data"];
      const param = {
        bc_id: "",
        bc_name: "",
        cs_data: "",
      };
      const params = {
        ...param,
        verify: this.$verify(param, order),
      };
      GetCompaniesStore()
        .then((res) => {
          // console.log(res);
          // this.filters.CompanystoreList.length = 0;
          this.CompanystoreList = [];
          this.CompanystoreList.push({
            value: "",
            label: "全部",
            cs_data: [],
          });

          this.treeData = res.data;
          for (let index = 0; index < res.data.length; index++) {
            const element = res.data[index];
            this.CompanystoreList.push({
              value: element.bc_id,
              label: element.bc_name,
              cs_data: element.cs_data,
            });
          }
          this.formOption.forEach((item) => {
            if (item.label == "设备所属分公司") {
              item.options = this.CompanystoreList;
            }
          });
        })
        .then(() => {
          this.valueList = [];
          this.treeData.forEach((el) => {
            this.valueList.push({ label: el.bc_name, value: el.bc_id });
          });
        });
    },
    TreeSelectChange(value, label, extra) {
      this.valueLabel = value.label;
      console.log(value);

      localStorage.setItem("TreeSelectValue", JSON.stringify(value));
      this.formOption.forEach((item) => {
        if (item.type == "TreeSelect") {
          if (JSON.stringify(this.valueList).includes(JSON.stringify(value))) {
            // console.log(111);
            this.form.bc_id = value.value;
            this.form.cs_id = "";
          } else {
            // console.log(222);
            let cs_id = value.value.split("-");
            this.form.cs_id = cs_id[1];
            this.form.bc_id = extra.triggerNode.$parent.value;
          }
        }
      });
      // console.log(this.form);
    },
    // 页面关闭之前，触发提示框
    beforeunloadHandler(e) {
      if (e) {
        e = e || window.event;
        // console.log(e);
        if (e) {
          // e.returnValue = "关闭提示";
          localStorage.removeItem("TreeSelectValue");
          localStorage.removeItem("patrolParam");
        }
        // return "关闭提示";
      }
    },
    // 页面关闭
    async unloadHandler(e) {
      // 退出登录
      // await this.handleGoLog();
    },
  },
  beforeDestroy() {
    // localStorage.removeItem("TreeSelectValue");
  },
};
</script>
<style lang="less" scoped>
/deep/ .ant-col-3 {
  // width: 11.5%;
  width: 10%;
  // width: auto;
  text-align: left;
}
.s-form {
  width: 100%;
  display: flex;

  /deep/ .ant-form-item-label {
    // width: 100px;
    color: white;
    width: 100px;
    width: auto;
    // padding-left: 10px;
    text-align: left;
  }
  /deep/ label {
    color: white;
  }
  /deep/ .ant-form-item-control-wrapper {
    flex: 1;
  }
  /deep/ label {
    // color: #ccc;
  }
  .ant-input {
    // color: #ccc;
    background-color: rgba(0, 0, 0, 0);
    &:focus {
      border-color: #47b4ea;
    }
    &:hover {
      border-color: #47b4ea;
    }
  }
  /deep/ .ant-select-selection {
    color: #ccc;
    background-color: rgba(0, 0, 0, 0);
    &:hover {
      border-color: #47b4ea;
    }
  }
  /deep/ .ant-select-selection__rendered {
    background-color: rgba(0, 0, 0, 0);
  }
  /deep/ .ant-select-arrow {
    color: #ccc;
  }
}
.s-button {
  height: 40px;
  text-align: left;
  // padding-left: 12px;
  padding-top: 4px;
}
.modal-select {
  margin: 0;
  text-align: left;
  color: #ccc;
}
.site-trans /deep/ .ant-transfer-list {
  // width: 560px;
  width: 45%;
  // width: 100%;
  // height: 10%;
  height: 400px;
}
/deep/.ant-transfer-list {
  // width: 450px;
  width: 45%;
  // width: 100%;
  height: 300px;
}
/deep/.ant-form-item-control-wrapper {
  // width: 110px;
  width: 100%;
}
/deep/ label {
  color: white;
}
/deep/ .ant-col-offset-3 {
  margin-left: 0;
}

/deep/ .ant-form-item-label {
  width: 100%;
  text-align: left;
  // background-color: pink;
  line-height: 25px;
}

.antd-row1 {
  display: flex;
  align-items: flex-end;
}
.s-button {
  bottom: 10px;
}
</style>
